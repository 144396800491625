<template>
  <!-- 富文本 -->
  <div class="richText">
    <div class="editor" v-if="themeData.richText" v-html="themeData.richText"></div>
    <div class="nodata" v-else>
      <img src="@/assets/storeConstruction/fuwenben.png" width="22" height="22" alt="">
      <p>新富文本</p>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: ['themeData'],
  name: 'richText',
  data () {
    return {
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
  },
}
</script>
<style scoped lang="less">
.richText {
  width: 100%;
  word-wrap: break-word;
  padding: 10px 12px;
  .nodata{
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>