<template>
  <div class="pageContol shopDecorate">
    <div class="framePage">
      <!-- <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">店铺装修</a>
        </span>
      </div> -->
      <div class="framePage-halfBody">
        <div class="framePage-body" @wheel="handleTableWheel($event)" v-drag="{ set: setVal, data: outData }">
          <!-- 左侧模块 -->
          <div class="tagList">
            <div class="tag" v-for="(item, index) in moduleList" :key="index"
              :class="mouseIndex === index ? 'active' : ''" @mouseenter="mouseenter(index)">
              <img :src="item.imgsrc">
              <p>{{ item.name }}</p>
              <div class="detail" v-if="mouseIndex === index" :key="index">
                <i class="icon el-icon-caret-left"></i>
                <div class="tag" v-for="item1 in item.detail" @click="moduleClick(item1.code)"
                  :class="currentCode === item1.code ? 'active' : ''" :key="item1.code">
                  <img :src="item1.imgsrc">
                  <p :style="{ 'color': currentCode === item1.code ? item.color : '#333' }">
                    {{ $setDictionary("CT_H5_MODULE", item1.code) }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 手机模块显示区 -->
          <vue-draggable-resizable w="auto" h="auto" :x="outData.moveX" :y="outData.moveY" :resizable="false"
            :draggable="false">
            <div class="phone" ref="phone" v-if="themeData.moduleList">
              <template v-for="(modelItem,index) in themeData.moduleList.filter(e=>e.enabled)">
                <component :is="codeMap[modelItem.code]=='title'?'subTitle':codeMap[modelItem.code]"
                  :themeData="themeData" :class="currentCode == modelItem.code ? 'activ' : ''"
                  @click.native="moduleClick(modelItem.code)">
                  <div class="operationBlock" v-if="currentCode == modelItem.code&&index !== 0">
                    <img @click="clickDel(modelItem)" src="@/assets/storeConstruction/cha.png" alt="">
                    <img v-if="index !== 1" @click="clickUp(modelItem.code)" src="@/assets/storeConstruction/shang.png" alt="">
                    <img v-if="(index !== themeData.moduleList.filter(e=>e.enabled).length - 1)" @click="clickDown(modelItem.code)" src="@/assets/storeConstruction/xia.png" alt="">
                  </div>
                </component>
              </template>
              <!-- 底部导航区域 -->
              <div class="phone_bot flexca flexac">
                <div class="box"  v-for="(item,index) in themeData.bottomModuleList" :key="index">
                  <p>{{item}}</p>
                </div>
              </div>
            </div>
          </vue-draggable-resizable>
        </div>
      </div>
    </div>
    <!-- 右侧设置模块 -->
    <div class="right">
      <!-- 店铺设置 -->
      <template v-if="currentCode == '00'">
        <titleForm key="00" :themeData="themeData" />
      </template>
      <!-- 轮播图 -->
      <template v-if="currentCode == '10'">
        <bannerListForm key="10" :themeData="themeData" />
      </template>
      <!-- 培训报名 -->
      <template v-if="currentCode == '20'">
        <listProjectForm key="20" :themeData="themeData" />
      </template>
      <!-- 图片导航 -->
      <template v-if="currentCode == '30'">
        <navigationListForm key="30" :themeData="themeData" />
      </template>
      <!-- 富文本 -->
      <template v-if="currentCode == '40'">
        <richTextForm key="40" :themeData="themeData" />
      </template>
    </div>
    <div class="bottom">
      <el-button size="medium" @click="reset">重置</el-button>
      <el-button type="primary" size="medium" @click="publish">发布</el-button>
    </div>
  </div>
</template>
<script>
// 店铺设置
import subTitle from './components/title/title.vue';
import titleForm from './components/title/titleForm.vue';
// 轮播图
import bannerList from './components/bannerList/bannerList.vue';
import bannerListForm from './components/bannerList/bannerListForm.vue';
// 图片导航
import navigationList from './components/navigationList/navigationList.vue';
import navigationListForm from './components/navigationList/navigationListForm.vue';
// 富文本
import richText from './components/richText/richText.vue';
import richTextForm from './components/richText/richTextForm.vue';
// 培训报名
import listProject from './components/listProject/listProject.vue';
import listProjectForm from './components/listProject/listProjectForm.vue';
export default {
  name: "operate/rightsManagement",
  components: { subTitle, bannerList, listProject, navigationList, titleForm, bannerListForm, navigationListForm, richText, richTextForm, listProjectForm },
  data () {
    return {
      outData: {
        moveX: 0,
        moveY: 0
      },
      // 分类索引
      mouseIndex: 0,
      // 模块list
      moduleList: [
        {
          imgsrc: require('@/assets/storeConstruction/dianpu.png'),
          name: '店铺信息',
          color: '#43A1FF',
          detail: [
            {
              imgsrc: require('@/assets/storeConstruction/shezhi.png'),
              code: '00',
            }
          ]
        },
        {
          imgsrc: require('@/assets/storeConstruction/jichu.png'),
          name: '基础组件',
          color: '#52DBB5',
          detail: [
            {
              imgsrc: require('@/assets/storeConstruction/lunbo.png'),
              code: '10',
            },
            {
              imgsrc: require('@/assets/storeConstruction/gaopin.png'),
              code: '30',
            },
            {
              imgsrc: require('@/assets/storeConstruction/fuwenben.png'),
              code: '40',
            }
          ]
        },
        {
          imgsrc: require('@/assets/storeConstruction/neirong.png'),
          name: '内容模块',
          color: '#FBB76B',
          detail: [
            {
              imgsrc: require('@/assets/storeConstruction/baoming.png'),
              code: '20',
            }
          ]
        },
      ],
      codeMap: {
        '00': 'title',
        '10': 'bannerList',
        '20': 'listProject',
        '30': 'navigationList',
        '40': 'richText',
      },
      themeData: {
        title: '',
        bannerList: [],
        listProject: [],
        navigationList: [],
        moduleList:[],
        richText: '',
      },
      // 当前选中的模块
      currentCode: '00',
    };
  },
  created () {
    this.init();
  },
  computed: {

  },
  mounted () {
    window.addEventListener('mousewheel', this.handleTableWheel, { passive: false })
  },
  destroyed () {
    window.removeEventListener('mousewheel', this.handleTableWheel, { passive: false })
  },
  methods: {
    // 拼接后台接口获得完整数据
    init () {
      Promise.all([this.getTheme(),this.getProject()]).then((res) => {
        this.themeData = res[0].data
        this.$set(this.themeData,'listProject',res[1].data)
      });
    },
    // 获取装修信息
    getTheme () {
      return this.$post("/biz/ct/theme/getTheme", {}, 3000, false)
    },
    // 获取已发布报名列表
    getProject () {
      return this.$post("/biz/ct/theme/listProject", {})
    },
    
    // 拖动phone
    setVal (x, y) {
      this.outData.moveX = x
      this.outData.moveY = y
    },
    // 放大phone
    handleTableWheel (event) {
      // 判断是不是按下ctrl键
      if (event.ctrlKey) {
        // 取消浏览器默认的放大缩小网页行为
        event.preventDefault()
        let obj = this.$refs.phone;
        if (this.$refs.phone) {
          return this.divZoom(obj, event);
        }
      }
    },
    // 放大phone
    divZoom (obj, event) {
      // 一开始默认是100%
      let scale = Number(obj.style.transform.replace(/[^\d.]/g, '')) || 1;
      scale += event.wheelDelta > 0 ? 0.05 : -0.05;
      if (scale > 0.5 && scale < 3) {
        obj.style.transform = "scale(" + scale + ")";
      }
      return false;
    },
    // 移入选择分类模块
    mouseenter (index) {
      if (this.mouseIndex === index) return
      this.mouseIndex = index
    },
    // 点击模块
    moduleClick (code) {
      this.currentCode = code
      let curModule = this.themeData.moduleList[this.isModule(code)]
      if (!curModule.enabled) {//未显示
        this.enableModule(curModule)
      }
      if (code == '30'&&this.themeData.navigationList.length==0) {
        this.themeData.iconNavigationSize = 4
        this.themeData.navigationList.push({}, {}, {}, {})
      }
    },
    // 展示/隐藏模块
    enableModule (item) {
      this.$post("/biz/ct/theme/enableModule", {enabled:!item.enabled,moduleId:item.moduleId}).then(res=>{
        this.getTheme().then(res1=>{
          this.themeData.moduleList = res1.data.moduleList
        })
      })
    },
    // 上移
    clickUp(code) {
      const curarr = this.themeData.moduleList.filter(e=>e.enabled)
      const index = curarr.findIndex(e=>e.code===code)
      if(index === 0) return
      this.moveModule(curarr[index-1].moduleId,curarr[index].moduleId)
    },
    // 下移
    clickDown(code) {
      const curarr = this.themeData.moduleList.filter(e=>e.enabled)
      const index = curarr.findIndex(e=>e.code===code)
      if(index === curarr.length - 1) return
      this.moveModule(curarr[index+1].moduleId,curarr[index].moduleId)
    },
    // 上下移动模块
    moveModule (prevModuleId,nextModuleId) {
      this.$post("/biz/ct/theme/moveModule", {prevModuleId,nextModuleId}).then(res=>{
        this.getTheme().then(res1=>{
          this.themeData.moduleList = res1.data.moduleList
        })
      })
    },
    // 删除
    clickDel(item) {
      this.$confirm(`是否删除${this.$setDictionary("CT_H5_MODULE", item.code)}?`, "提示", {
        confirmButtonText: "确定",
        confirmButtonClass: "confirmButtonClass",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        this.enableModule(item)
        this.currentCode = '00'
      })
    },
    // 重置
    reset(){
      this.$confirm(`重置后未保存的配置信息将会丢失，确定重置吗？`, "提示", {
        confirmButtonText: "确定",
        confirmButtonClass: "confirmButtonClass",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        this.init()
      })
    },
    // 发布
    publish(){
      if(this.dataVerify()){
        let data = {...this.themeData}
        data.projectIds = this.themeData.listProject.map(e=>e.projectId)
        this.$post("/biz/ct/theme/publishTheme", data).then(res=>{
          this.$message.success('保存成功！')
          this.init()
        })
      }
    },
    // 数据校验
    dataVerify(){
      let curModule = this.themeData.moduleList.filter(e=>e.enabled)
      for (const index in curModule) {
        const element = curModule[index];
        const curkey = this.themeData[this.codeMap[element.code]]
        if(!curkey||curkey?.length===0){
          this.$message.error(`请完善${this.$setDictionary("CT_H5_MODULE", element.code)}信息`)
          return false
        }
        if(element.code=='30'&&!this.highFrequentVerify(curkey)){
          console.log(!this.highFrequentVerify(curkey));
          this.$message.error(`请完善${this.$setDictionary("CT_H5_MODULE", element.code)}信息`)
          return false
        }
      }
      return true
    },
    // 单独校验高频模块
    highFrequentVerify(arr){
      if(arr.length==0) return false
      for (const index in arr) {
        const element = arr[index];
        if((element.categoryId||element.componentId)||element.iconKey){
          if(!((element.categoryId||element.componentId)&&element.iconKey)){
            return false
          }
        }
      }
      let result = arr.some(e=>(e.categoryId||e.componentId)&&e.iconKey)
      return result
    },
    /**
     * @description: 当前点击模块索引
     * @return {*}
     */
    isModule (code) {
      return this.themeData.moduleList.findIndex(e => e.code == code)
    },
  },
};
</script>

<style lang="less" scoped>
.vdr {
  &.active {
    color: #2c3e50;
  }

  border: none;
}

.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
  user-select: none;

  .framePage-body {
    position: relative;
    background: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 50px 400px 0 150px;

    .tagList {
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 10;
      display: flex;
      flex-direction: column;

      .tag {
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #F8F8F8;
        position: relative;

        img {
          width: 40px;
          height: 40px;
          margin-bottom: 8px;
        }

        p {
          font-size: 14px;
          color: #333333;
        }

        .detail {
          position: absolute;
          right: -115px;
          top: 10px;

          .icon {
            position: absolute;
            font-size: 18px;
            top: 38px;
            left: -12px;
            color: #fff;
          }

          .tag {
            width: 100px;
            height: 100px;
            background: #ffffff;
            cursor: pointer;

            img {
              filter: grayscale(100%);
            }

            &.active {
              img {
                filter: none;
              }
            }
          }
        }
      }

      .tag.active {
        background: #fff;

        img {
          filter: none;
        }
      }
    }

    .phone {
      position: relative;
      width: 375px;
      min-height: 650px;
      background: #F5F5F5;
      box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.08);
      padding-bottom: 49px;
      >div:not(.phone_bot) {
        position: relative;
        box-sizing: border-box;
        border: 2px dashed transparent;
        &:hover,
        &.activ {
          border: 2px dashed #2878FF;
        }
        .operationBlock{
          position: absolute;
          top: 0;
          right: -76px;
          padding: 12px;
          display: flex;
          flex-direction: column;
          background: #fff;
          img{
            width: 32px;
            height: 32px;
            margin-bottom: 8px;
            cursor: pointer;
            &:last-of-type{
              margin-bottom: 0;
            }
          }
        }
      }
      

      .phone_bot {
        position: absolute;
        bottom: 0;
        left: 0;
        background: #fff;
        width: 100%;
        .box {
          width: 25%;
          float: left;
          height: 49px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          background: #fff;

          img {
            width: 22px;
            height: 22px;
          }

          p {
            font-size: 14px;
            color: #666666;
          }
        }

        .box:first-of-type p {
          color: #2878FF;
        }
      }
    }
  }
}

/deep/ .el-form-item__label {
  width: 100%;
}

.pageContol {
  position: relative;

  .right {
    position: absolute;
    right: 0;
    top: 0;
    width: 400px;
    height: 100%;
    background: #fff;
    z-index: 2;
    padding: 30px 40px 60px 20px;
    overflow-y: auto;

    /deep/ .header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;

      img {
        width: 28px;
        height: 28px;
        margin-right: 5px;
      }

      p {
        font-size: 15px;
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .bottom{
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding-left: 150px;
    background: #fff;
    width: calc(100% - 400px);
    box-shadow: 0px -3px 12px 1px rgba(0,0,0,0.08);
    z-index: 33;
  }
}
</style>
