<template>
  <div>
    <div class="header">
      <img src="@/assets/storeConstruction/gaopin.png" alt="">
      <p>图片导航设置</p>
    </div>
    <el-form label-position="top" size="small" label-width="80px" :model="themeData">
      <el-form-item label="选择图标个数">
        <el-radio-group v-model="themeData.iconNavigationSize" @change="iconNavigationChange">
          <el-radio :label="4">4个</el-radio>
          <el-radio :label="5">5个</el-radio>
          <el-radio :label="8">8个</el-radio>
          <el-radio :label="10">10个</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-for="(domain, index) in themeData.navigationList">
        <div style="background: #F5F5F5;border-radius: 8px;padding: 20px;color: #666666;margin-bottom: 10px;">
          <div class="flexcc">
            <img :src="domain.navigationURL||require('@/assets/storeConstruction/tbdh.png')"
              @click="() => { selectIconStyleState = true; curSelectIcon.curIndex = index }"
              style="margin-right: 12px;cursor: pointer;" width="38" height="38" alt="">
            <el-input :class="domain.componentName&&domain.componentName.length>4?'boderR':''" v-model="domain.componentName" readonly></el-input>
          </div>
          <span v-if="domain.componentName&&domain.componentName.length>4" style="font-size: 12px;color: red;">导航名称超过4个字将显示不全，请修改原链接名称</span>
          <div class="mylabel" style="padding-left: 50px;margin-top: 10px;">
            <el-button type="primary" size="small" @click="selectLinkShow(domain,index)">跳转链接</el-button>
            <div class="flexcc" style="cursor: pointer;">
              <img v-if="(index !== 0)" @click="clickUp(index)" src="@/assets/storeConstruction/shang.png" style="margin-right: 5px;" width="32" height="32" alt="">
              <img v-if="(index !== themeData.navigationList.length - 1)" @click="clickDown(index)"  src="@/assets/storeConstruction/xia.png" style="margin-right: 5px;" width="32" height="32" alt="">
              <img @click="clickDel(index)" src="@/assets/storeConstruction/cha.png" width="32" height="32" alt="">
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <!-- 选择图标样式 -->
    <el-dialog v-if="selectIconStyleState" append-to-body title="选择图标样式" :visible.sync="selectIconStyleState" :before-close="doClose"
      width="856px">
      <div class="df" style="flex-wrap: wrap;">
        <div class="flexcc dialogsty"
          :class="(curSelectIcon.curItem.iconKey && curSelectIcon.curItem.iconKey === item.iconKey) ? 'active' : ''"
          @click="selectIcon(item)" v-for="item in selectIconList" :key="item.iconKey">
          <img :src="item.navigationURL" width="38" height="38" alt="">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="doClose" size="small">取 消</el-button>
        <el-button class="bgc-bv" @click="selectIconSubmit" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择图标样式 -->
    <!-- 选择跳转链接 -->
    <el-dialog v-if="selectLinkState" title="选择跳转链接" append-to-body :visible.sync="selectLinkState" :before-close="doClose1"
      width="500px">
      <div>
        <el-tabs v-model="navigationType">
          <el-tab-pane :label="item.label" :name="item.value" :key="item.value" v-for="item in navigationTypeList">
            <template v-if="item.value == '00'">
              <el-table ref="multipleTable" :data="selectNavigationLink" tooltip-effect="dark" style="width: 100%"
                @row-click="singleElection" highlight-current-row>
                <el-table-column width="80" label="选择">
                  <template slot-scope="scope">
                    <el-radio class="radio" v-model="checkObj" :label="scope.row"><span></span></el-radio>
                  </template>
                </el-table-column>
                <el-table-column label="序号" align="center" type="index"/>
                <el-table-column label="应用名称" align="center" prop="componentName"/>
              </el-table>
            </template>
            <template v-if="item.value == '01'">
              <el-table ref="multipleTable" :data="themeData.categoryList" tooltip-effect="dark" style="width: 100%"
                @row-click="singleElection1" highlight-current-row>
                <el-table-column width="80" label="选择">
                  <template slot-scope="scope">
                    <el-radio class="radio" v-model="checkObj1" :label="scope.row"><span></span></el-radio>
                  </template>
                </el-table-column>
                <el-table-column label="序号" align="center" type="index"/>
                <el-table-column label="分类名称" align="center" prop="categoryName"/>
              </el-table>
            </template>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="doClose1" size="small">取 消</el-button>
        <el-button class="bgc-bv" @click="selectLinkSubmit" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择跳转链接 -->
  </div>
</template>
<script>
import { selectIconList } from './selectIconList.js';
export default {
  props: ['themeData'],
  name: 'navigationListForm',
  data () {
    return {
      // 选择图标样式
      selectIconStyleState: false,
      selectIconList: selectIconList,
      curSelectIcon: {
        curItem: {},
        curIndex: 0
      },
      // 选择链接
      selectLinkState: false,
      // 跳转链接列表
      selectNavigationLink: [],
      // 导航类型
      navigationType: '00',
      // 跳转链接分类数据字典
      navigationTypeList: [],
      //当前行选中的应用数据row 
      checkObj: {},
      //当前行选中的分类数据row 
      checkObj1: {},
    }
  },
  created () {
    this.dataDictionary()
    this.selectNavigationComponent()
  },
  computed: {
  },
  methods: {
    // 获取图标导航，bannner跳转地址
    selectNavigationComponent () {
      return this.$post("/biz/ct/theme/selectNavigationComponent", {}).then((res) => {
        if (res.status == 0) {
          this.selectNavigationLink = res.data
        }
      });
    },
    // 获取 - 数据字典
    dataDictionary () {
      // 导航类型
      const navigationTypeList = this.$setDictionary(
        "CT_NAVIGATION_TYPE",
        "list"
      );
      const list = [];
      for (const key in navigationTypeList) {
        list.push({
          value: key,
          label: navigationTypeList[key],
        });
      }
      this.navigationTypeList = list;
      console.log(list);
    },
    // 图片导航方法----------------------------
    // 导航个数改变
    iconNavigationChange (val) {
      if (!val) return
      let difference = Math.abs(val - this.themeData.navigationList.length)
      let differenceObj = Array.from({ length: difference }, x => { return {} })
      if (val > this.themeData.navigationList.length) {
        this.themeData.navigationList.push(...differenceObj)
      } else if (val < this.themeData.navigationList.length) {
        this.themeData.navigationList = this.themeData.navigationList.slice(0, val)
      }
    },
    // 选择图标
    selectIcon (item) {
      this.curSelectIcon.curItem = item
    },
    // 清空数据
    doClose () {
      this.curSelectIcon = this.$options.data().curSelectIcon
      this.selectIconStyleState = false
    },
    // 选择图标确认
    selectIconSubmit () {
      this.$set(this.themeData.navigationList[this.curSelectIcon.curIndex], 'iconKey', this.curSelectIcon.curItem.iconKey)
      this.$set(this.themeData.navigationList[this.curSelectIcon.curIndex], 'navigationURL', this.curSelectIcon.curItem.navigationURL)
      this.doClose()
    },
    // 打开选择链接
    selectLinkShow (domain,index) {
      this.selectLinkState = true;
      if(domain.componentId){
        this.navigationType = '00'
        this.checkObj = this.selectNavigationLink.find(e=>e.componentId == domain.componentId)
      }
      if(domain.categoryId){
        this.navigationType = '01'
        this.checkObj1 = this.themeData.categoryList.find(e=>e.categoryId == domain.categoryId)
      }
      
      this.curSelectIcon.curIndex = index
    },
    // 选择应用链接
    singleElection (row) {
      this.checkObj = row;
    },
    // 选择分类链接
    singleElection1 (row) {
      this.checkObj1 = row;
    },
    // 清空数据
    doClose1 () {
      this.curSelectIcon = this.$options.data().curSelectIcon
      this.checkObj = {};
      this.checkObj1 = {};
      this.navigationType = '00'
      console.log(1);
      this.selectLinkState = false
    },
    // 选择链接确认
    selectLinkSubmit () {
      let curItem = this.themeData.navigationList[this.curSelectIcon.curIndex]
      if (this.navigationType == '00') {
        // 应用
        this.$set(curItem, 'categoryId', undefined)
        this.$set(curItem, 'componentId', this.checkObj.componentId)
        this.$set(curItem, 'componentName', this.checkObj.componentName)
        this.$set(curItem, 'navigationPath', this.checkObj.redirectPath)
        this.$set(curItem, 'navigationType', this.navigationType)
      } else if (this.navigationType == '01') {
        // 分类
        this.$set(curItem, 'componentId', undefined)
        this.$set(curItem, 'categoryId', this.checkObj1.categoryId)
        this.$set(curItem, 'componentName', this.checkObj1.categoryName)
        this.$set(curItem, 'navigationPath', `/home/moreRegistrations?categoryId=${this.checkObj1.categoryId}`)
        this.$set(curItem, 'navigationType', this.navigationType)
      }
      this.doClose1()
    },
    // 上移
    clickUp(index) {
      if(index === 0) return
      let arr = this.themeData.navigationList
      arr.splice(index - 1, 1, ...arr.splice(index, 1, arr[index - 1]));
    },
    // 下移
    clickDown(index) {
      let arr = this.themeData.navigationList
      if(index === arr.length - 1) return
      arr.splice(index, 1, ...arr.splice(index + 1, 1, arr[index]));
    },
    // 清空
    clickDel(index) {
      let curItem = this.themeData.navigationList[index]
      console.log(curItem);
      this.$set(curItem, 'categoryId', undefined)
      this.$set(curItem, 'componentId', undefined)
      this.$set(curItem, 'componentName', undefined)
      this.$set(curItem, 'navigationPath', undefined)
      this.$set(curItem, 'iconKey', undefined)
      this.$set(curItem, 'navigationURL', undefined)
    },
    // 图片导航方法----------------------------
  },
}
</script>
<style scoped lang="less">
.dialogsty {
  width: 90px;
  height: 90px;
  border: 1px solid #F5F5F5;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;

  &.active {
    background: #F5F5F5;
  }
}
.mylabel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.boderR /deep/ .el-input__inner{
  border:1px solid red
}
</style>