<template>
  <div>
    <div class="header">
      <img src="@/assets/storeConstruction/fuwenben.png" alt="">
      <p>富文本设置</p>
    </div>
    <div
      ref="editor"
      style="width: 100%; position: relative; z-index: 2"
    />
  </div>
</template>
<script>
import E from "wangeditor";
let editor = {};
export default {
  props: ['themeData'],
  name: 'richTextForm',
  data () {
    return {
    }
  },
  mounted () {
    this.editorInit();
    editor.txt.html(this.themeData.richText)
  },
  computed: {
  },
  methods: {
    // 富文本
    editorInit () {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "image", // 插入图片
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = false;
      editor.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
          files[0].name.lastIndexOf(".") + 1,
          files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            insert(result.data.fileURL);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      };
      editor.config.onchange = (html) => {
        this.$set(this.themeData,'richText',html)
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      
    },
  },
}
</script>
<style scoped lang="less"></style>