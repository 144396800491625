<template>
<!-- 店铺信息 -->
<div class="title" v-if="themeData.title">
  {{ themeData.title }}
  <slot></slot>
</div>
<!-- 店铺信息 -->
</template>
<script>
export default {
  props:['themeData'],
  name:'title',
   data() {
      return {
      }
   },
   created(){
   },
   computed:{
   },
   methods:{
   },
}
</script>
<style scoped>
.title {
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  color: #333333;
}
</style>