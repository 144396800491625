<template>
  <!-- 报名 -->
  <div class="listProject">
    <div class="header">
      <div>在线报名</div>
      <div>更多<i class="icon el-icon-arrow-right"></i></div>
    </div>
    <template v-if="themeData.listProject&&themeData.listProject.length > 0">
      <div class="box" :class="applyStyleMap[themeData.applyStyle]" v-for="item in themeData.listProject" :key="item.projectId">
        <template v-if="themeData.applyStyle === '0'">
          <div class="first">
            <h4>{{ item.projectName }}</h4>
            <p>截止日期：{{ item.endDate }}</p>
          </div>
          <div class="second">
            <p>已报名人数：{{ item.peopleNum }}人</p>
            <el-tag size="mini" :type="item.state == '已结束' ? 'info' : ''">{{ item.state }}</el-tag>
          </div>
        </template>
        <template v-if="themeData.applyStyle === '1'">
          <img :src="item.cover||require('@/assets/storeConstruction/pxbm.png')" alt="">
          <div class="content">
            <p>{{ item.projectName }}</p>
            <div class="text">
              <el-tag size="mini" :type="item.state == '已结束' ? 'info' : ''">{{ item.state }}</el-tag>
              <p>截止日期：{{ item.endDate }}</p>
            </div>
          </div>
        </template>
      </div>
    </template>
    <slot></slot>
  </div>
  <!-- 报名 -->
</template>
<script>
export default {
  props: ['themeData'],
  name: 'listProject',
  data () {
    return {
      applyStyleMap: {
        '0': 'txt',
        '1': 'img',
      }
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
  },
}
</script>
<style scoped lang="less">
.listProject {
  padding: 10px 12px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    div:first-of-type {
      font-size: 16px;
      font-weight: bold;
      color: #666666;
    }

    div:last-of-type {
      font-size: 12px;
      color: #999999;
    }
  }

  .box.img {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 12px 8px;
    height: 114px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    img {
      min-width: 160px;
      width: 160px;
      min-height: 90px;
      height: 90px;
      margin-right: 8px;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: 100%;

      >p {
        font-size: 14px;
        color: #333333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .el-tag {
          margin-bottom: 3px;
        }

        p {
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }

  .box.txt {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 12px 8px;
    min-height: 100px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      font-size: 12px;
      color: #666666;
    }

    .first {
      h4 {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 5px;
      }
    }

    .second {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}</style>