<template>
  <div>
    <div class="header">
      <img src="@/assets/storeConstruction/lunbosetting.png" alt="">
      <p>轮播图设置</p>
    </div>
    <el-form label-position="top" size="small" label-width="80px" :model="themeData">
      <el-form-item>
        <div slot="label" class="mylabel">
          <span>轮播图</span>
          <!-- <span @click="copyUrl(themeData.link)" style="color:#2878FF;cursor: pointer;">添加轮播图</span> -->
        </div>
        <div v-for="(item, index) in themeData.bannerList" :key="item.bannerKey"
          style="background: #F5F5F5;border-radius: 8px;padding: 20px;color: #666666;margin-bottom: 20px;">
          <el-upload :on-change="(res) => { handleAvatarSuccess2(res, index) }" :before-upload="$beforeAvatarUpload"
            :http-request="$requestMine" :show-file-list="false" class="img-el-upload myupload2" action
            accept="image/png, image/gif, image/jpg, image/jpeg">
            <el-image :src="item.bannerURL" fit="contain" class="imgCenter"></el-image>
          </el-upload>
          <div class="mylabel">
            <el-input v-model="item.bannerPath" style="width: 180px;" placeholder="跳转链接" size="small"></el-input>
            <div class="flexcc" style="cursor: pointer;">
              <img v-if="(index !== 0)" @click="clickUp(index)" src="@/assets/storeConstruction/shang.png" style="margin-right: 5px;" width="32" height="32" alt="">
              <img v-if="(index !== themeData.bannerList.length - 1)" @click="clickDown(index)" src="@/assets/storeConstruction/xia.png" style="margin-right: 5px;" width="32" height="32" alt="">
              <img @click="clickDel(index)" src="@/assets/storeConstruction/cha.png" width="32" height="32" alt="">
            </div>
          </div>
        </div>
        <div v-if="themeData.bannerList.length<=10" style="background: #F5F5F5;border-radius: 8px;padding: 20px;color: #666666;">
          <el-upload :on-change="(res) => { handleAvatarSuccess2(res, themeData.bannerList.length) }"
            :before-upload="$beforeAvatarUpload" :http-request="$requestMine" :show-file-list="false"
            class="img-el-upload myupload2" action accept="image/png, image/gif, image/jpg, image/jpeg">
            <template>
              <div class="flexdcc">
                <img src="@/assets/storeConstruction/lunbo2.png" width="40" height="40" alt="">
                <p>添加banner</p>
              </div>
            </template>
          </el-upload>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: ['themeData'],
  name: 'bannerListForm',
  data () {
    return {
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
    // 轮播图方法----------------------------
    //上传轮播图
    handleAvatarSuccess2 (res, index) {
      this.uploadFetch(res.raw, index);
    },
    uploadFetch (file, index) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      if (file.size / 1024 / 1024 > 1) {
        this.$message.error("文件大小不能超过1MB");
        return;
      }
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      this.$Postformat("/sys/upload", formData).then((ret) => {
        !this.themeData.bannerList[index] && (this.themeData.bannerList.push({}))
        this.$set(this.themeData.bannerList[index], 'bannerKey', ret.data.fileKey)
        this.$set(this.themeData.bannerList[index], 'bannerURL', ret.data.fileURL)
      });
    },
    // 上移
    clickUp(index) {
      if(index === 0) return
      let arr = this.themeData.bannerList
      arr.splice(index - 1, 1, ...arr.splice(index, 1, arr[index - 1]));
    },
    // 下移
    clickDown(index) {
      let arr = this.themeData.bannerList
      if(index === arr.length - 1) return
      arr.splice(index, 1, ...arr.splice(index + 1, 1, arr[index]));
    },
    // 删除
    clickDel(index) {
      this.themeData.bannerList.splice(index, 1);
    },
    // 轮播图方法----------------------------
  },
}
</script>
<style scoped lang="less">
.myupload2 {
  /deep/ .el-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    margin: 0 auto;
    max-width: none;
    height: 115px;
    border-radius: 8px;
    margin-bottom: 10px;
    border: 1px dashed #43A1FF;
    background: #fff;
    color: #43A1FF;
  }
}
.mylabel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>