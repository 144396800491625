<template>
  <div>
    <!-- 店铺信息 -->
    <div class="header"><img src="@/assets/storeConstruction/dianpu.png" alt="">
      <p>店铺信息设置</p>
    </div>
    <el-form label-position="top" size="small" label-width="80px" :model="themeData">
      <el-form-item label="店铺名称">
        <el-input v-model="themeData.title" maxlength="20" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="店铺LOGO">
        <el-upload :on-change="handleAvatarSuccess" :before-upload="$beforeAvatarUpload" :http-request="$requestMine"
          :show-file-list="false" class="img-el-upload myupload1" action
          accept="image/png, image/gif, image/jpg, image/jpeg">
          <el-image :src="themeData.logoUrl || require('@/assets/imgdefault.png')" fit="contain"
            class="imgCenter"></el-image>
        </el-upload>
      </el-form-item>
      <el-form-item label="店铺链接">
        <div slot="label" class="mylabel">
          <span>店铺链接</span>
          <span @click="copyUrl(themeData.link)" style="color:#2878FF;cursor: pointer;">点击复制</span>
        </div>
        <div style="background: #F5F5F5;border-radius: 8px;padding: 10px;color: #666666;">{{ themeData.link }}</div>
      </el-form-item>
      <el-form-item label="店铺二维码">
        <div slot="label" class="mylabel">
          <span>店铺二维码</span>
          <span @click="downloadQRcode(themeData.qrCodeDownloadURL)" style="color:#2878FF;cursor: pointer;">点击下载</span>
        </div>
        <img :src="themeData.qrCodeURL" width="94" height="94" alt="">
      </el-form-item>
    </el-form>
  </div>
  <!-- 店铺信息 -->
</template>
<script>
export default {
  props: ['themeData'],
  name: 'titleFrom',
  data () {
    return {
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
    // 店铺信息方法----------------------------
    //图片上传
    handleAvatarSuccess (res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.$set(this.themeData,'logo',result.data.fileKey || "")
            this.$set(this.themeData,'logoUrl',result.data.fileURL || "")
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 复制到剪贴板
    copyUrl (data) {
      let url = data;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "已成功复制到剪切板",
        type: "success",
      });
      oInput.remove();
    },
    // 下载二维码
    downloadQRcode (url) {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "报名二维码");
      document.body.appendChild(link);
      link.click();
    },
    // 店铺信息方法----------------------------
  },
}
</script>
<style scoped lang="less">
.myupload1 /deep/ .el-upload {
  width: 94px;
  height: 94px;
  border: none;
}
.mylabel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>