// 这个是图标导航-选择图标的固定图标数据
const selectIconList = [
  {
    iconKey: 'constant/home/1.png',
    navigationURL: 'https://gszyjk.oss-cn-beijing.aliyuncs.com/constant/home/1.png',
  },
  {
    iconKey: 'constant/home/2.png',
    navigationURL: 'https://gszyjk.oss-cn-beijing.aliyuncs.com/constant/home/2.png',
  },
  {
    iconKey: 'constant/home/3.png',
    navigationURL: 'https://gszyjk.oss-cn-beijing.aliyuncs.com/constant/home/3.png',
  },
  {
    iconKey: 'constant/home/4.png',
    navigationURL: 'https://gszyjk.oss-cn-beijing.aliyuncs.com/constant/home/4.png',
  },
]
export { selectIconList }