<template>
  <!-- 图标导航 -->
  <div class="navigationList">
    <div class="boxWrap" v-if="themeData.navigationList.length > 0">
      <div class="box" v-for="(item,index) in themeData.navigationList" :key="index" :style="{ 'width': resWidth(themeData.iconNavigationSize) }">
        <img :src="item.navigationURL||require('@/assets/storeConstruction/tbdh.png')" >
        <p>{{item.componentName}}</p>
      </div>
    </div>
    <div v-else style="height: 84px;width: 100%;text-align: center;line-height: 84px;font-size: 30px;color: #333;">
      图标导航
    </div>
    <slot></slot>
  </div>
  <!-- 图标导航 -->
</template>
<script>
export default {
  props: ['themeData'],
  name: 'navigationList',
  data () {
    return {
    }
  },
  created () {
    console.log(this.themeData.navigationList);
  },
  computed: {
  },
  methods: {
    // 计算图标导航宽度
    resWidth (amount) {
      switch (amount) {
        case 4:
          return '25%'
          break;
        case 5:
          return '20%'
          break;
        case 8:
          return '25%'
          break;
        case 10:
          return '20%'
          break;
        default:
          break;
      }
    },
  },
}
</script>
<style scoped lang="less">
.boxWrap {
  overflow: hidden;

  .box {
    width: 25%;
    float: left;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 0;
    flex-direction: column;
    position: relative;

    img {
      width: 38px;
      height: 38px;
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
      color: #333333;
    }
  }
}
</style>