<template>
  <div>
    <!-- 在线报名设置 -->
    <div class="header"><img src="@/assets/storeConstruction/baoming2.png" alt="">
      <p>在线报名设置</p>
    </div>
    <el-form label-position="top" size="small" label-width="80px" :model="themeData">
      <el-form-item label="选择样式">
        <el-radio-group v-model="themeData.applyStyle">
          <el-radio v-for="item in list1" :label="item.value" :key="item.value">{{ item.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="排序方法">
        <el-radio-group v-model="themeData.applySortType" @change="applySortTypeChange">
          <el-radio v-for="item in list2" :label="item.value" :key="item.value">{{ item.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="themeData.listProject.length>0">
        <el-form-item v-for="(item,index) in themeData.listProject" :key="item.projectId">
          <div style="background: #F5F5F5;border-radius: 8px;padding: 10px;height: 88px;color: #333333;position: relative;">
            <div class="flexca" style="height: 100%;">
              <img :src="item.cover || require('@/assets/storeConstruction/pxbm.png')"
                style="margin-right: 8px;border-radius: 8px;" width="96" height="54" alt="">
              <p>{{ item.projectName }}</p>
            </div>
          </div>
          <template v-if="themeData.applySortType == '1'">
            <div class="operationBlock">
              <img @click="clickDel(index)" src="@/assets/storeConstruction/cha.png" alt="">
              <img v-if="(index !== 0)" @click="clickUp(index)" src="@/assets/storeConstruction/shang.png" alt="">
              <img v-if="(index !== themeData.listProject.length - 1)" @click="clickDown(index)"  src="@/assets/storeConstruction/xia.png" alt="">
            </div>
          </template>
        </el-form-item>
      </template>
      <el-form-item v-if="themeData.applySortType=='1'">
        <div @click="state = true"
          style="border-radius: 8px;padding: 10px;height: 88px;color: #43A1FF;border: 1px dashed #43A1FF;position: relative;cursor: pointer;">
          <div class="flexcc" style="height: 100%;">添加报名</div>
        </div>
      </el-form-item>
    </el-form>
    <!-- 选择报名 -->
    <el-dialog v-if="state" title="选择报名" append-to-body :visible.sync="state" :before-close="doClose" width="500px">
      <div>
        <el-table ref="multipleTable" :data="listMinuProject" tooltip-effect="dark" style="width: 100%"
          @selection-change="handleSelectionChange" highlight-selection-row>
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column label="序号" align="center"  type="index" width="80" />
          <el-table-column label="报名名称" align="center"  prop="projectName" />
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="doClose" size="small">取 消</el-button>
        <el-button class="bgc-bv" @click="selectLinkSubmit" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择报名 -->
  </div>
</template>
<script>
export default {
  props: ['themeData'],
  name: 'listProjectForm',
  data () {
    return {
      // 报名样式
      list1: [],
      // 报名排序方式
      list2: [],
      listAllProject: [],
      state: false,
      multipleSelection: []
    }
  },
  created () {
    this.dataDictionary()
    this.getAllProject()
  },
  computed: {
    listMinuProject() {
      const minusion = this.listAllProject.filter((inv) =>
        this.themeData.listProject.every((sel) => sel.projectId !== inv.projectId)
      );
      return [...minusion];
    },
  },
  methods: {
    // 获取全部报名列表
    getAllProject () {
      return this.$post("/biz/ct/theme/listAllProject", {}).then((res) => {
        if (res.status == 0) {
          this.listAllProject = res.data
          if(this.themeData.applySortType == '0'){
            this.themeData.listProject = this.listAllProject.slice(0,5)
          }
        }
      });
    },
    // 获取 - 数据字典
    dataDictionary () {
      // 报名样式
      const list1 = this.$setDictionary(
        "CT_APPLY_STYLE",
        "list"
      );
      const list = [];
      for (const key in list1) {
        list.push({
          value: key,
          label: list1[key],
        });
      }
      this.list1 = list;
      // 报名排序方式
      const list2 = this.$setDictionary(
        "CT_APPLY_SORT_TYPE",
        "list"
      );
      const list3 = [];
      for (const key in list2) {
        list3.push({
          value: key,
          label: list2[key],
        });
      }
      this.list2 = list3;
    },
    // 选择报名
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 清空数据
    doClose () {
      this.multipleSelection = []
      this.state = false
    },
    // 提交
    selectLinkSubmit () {
      this.multipleSelection.forEach(e=>{
        this.themeData.listProject.push(e)
      })
      this.multipleSelection = []
      this.state = false
    },
    // 上移
    clickUp(index) {
      if(index === 0) return
      let arr = this.themeData.listProject
      arr.splice(index - 1, 1, ...arr.splice(index, 1, arr[index - 1]));
    },
    // 下移
    clickDown(index) {
      let arr = this.themeData.listProject
      if(index === arr.length - 1) return
      arr.splice(index, 1, ...arr.splice(index + 1, 1, arr[index]));
    },
    // 删除
    clickDel(index) {
      this.themeData.listProject.splice(index, 1);
    },
    applySortTypeChange(val){
     switch (val) {
      case '0':
        this.themeData.listProject = this.listAllProject.slice(0,5)
        break;
      case '1':
        this.themeData.listProject = this.themeData.listProject.slice(0,0)
        console.log(this.themeData.listProject);
        break;
      default:
        break;
     }
    }

  },
}
</script>
<style scoped lang="less">
.flexca p {
  font-size: 14px;
  color: #333333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  line-height: 1.6em;
}

.operationBlock {
  position: absolute;
  right: -34px;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  img {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }
}
</style>