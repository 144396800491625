<template>
  <!-- 轮播图 -->
  <div class="bannerList">
    <el-carousel style="width: 100%;padding: 10px 12px;" height="130px" loop :interval="2000"
      v-if="themeData.bannerList.length > 0">
      <el-carousel-item style="border-radius: 8px;"
        v-for="(item, index) in themeData.bannerList.filter((el) => el.bannerURL)" :key="index">
        <img style="width: 100%; height: 100%" :src="item.bannerURL" alt="" />
      </el-carousel-item>
    </el-carousel>
    <div v-else style="height: 130px;width: 100%;text-align: center;line-height: 130px;font-size: 30px;color: #333;">
      轮播图
    </div>
    <slot></slot>
  </div>
  <!-- 轮播图 -->
</template>
<script>
export default {
  props: ['themeData'],
  name: 'bannerList',
  data () {
    return {
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
  },
}
</script>
<style scoped>
</style>